


































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'TheZeroInvitations',
})
export default class TheZeroInvitations extends Vue {
  createTeam () {
    window.goal('onboardingAction', { onboarding: 'Приглашения — «Создать команду»' })
    this.$router.push({ name: 'CreateTeam' })
  }
}

